import type {
  LinksFunction,
  LoaderArgs,
  LoaderFunction,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { APP_ENV, COMMIT_SHA, SENTRY_DSN } from "~/env.server";
import { getUser } from "./session.server";
import customStylesheetUrl from "./styles/custom.css";
import tailwindStylesheetUrl from "./styles/tailwind.css";

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    { rel: "stylesheet", href: customStylesheetUrl },
    {
      rel: "stylesheet",
      href: "https://rsms.me/inter/inter.css", // https://tailwindui.com/
    },
  ];
};

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: "CertStack",
  viewport: "width=device-width,initial-scale=1",
});

export const loader: LoaderFunction = async ({ request }: LoaderArgs) => {
  return json({
    user: await getUser(request),
    // env variables for the client
    // see https://remix.run/docs/en/v1/guides/envvars
    ENV: {
      SENTRY_DSN,
      COMMIT_SHA,
      APP_ENV,
    },
  });
};

function App() {
  const data = useLoaderData<typeof loader>();
  return (
    <html lang="en" className="h-full bg-gray-100">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <LiveReload />
      </body>
    </html>
  );
}

export default withSentry(App);
